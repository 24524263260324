import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Dashboard from "../../../components/Dashboard";
import {
  PageHeight30,
  WizardTitle,
  notifyError,
} from "../../../components/Utils";
import { FormMenu } from "../../../components/Buttons";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { loadDevice, cancelDevice, saveDevice } from "../actions";

import DeviceForm from "../components/DeviceForm";

const frontload = async (props) => {
  const id = props.match.params.id;
  await props.getDevice({ id });
};
const mapStateToProps = (state) => {
  return {
    device: state.device.device,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getDevice: (...args) => dispatch(loadDevice(...args)),
  cancelDevice: (...args) => dispatch(cancelDevice(...args)),
  saveDevice: (...args) => dispatch(saveDevice(...args)),
});

class Wizard extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getDevice: PropTypes.func.isRequired,
    cancelDevice: PropTypes.func.isRequired,
    saveDevice: PropTypes.func.isRequired,
    className: PropTypes.string,
  };
  static defaultProps = {
    className: "",
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillUnmount() {
    this.props.cancelDevice();
  }
  onCancel = () => {
    // console.log("DeviceWizard.onCancel()");
    const { cancelDevice, history } = this.props;
    cancelDevice();
    history.replace("/organisation/messgeraete/admin");
  };
  onSave = () => {
    // console.log("DeviceWizard.onSave()");
    const model = this.form.getModel();
    if (!model) {
      return notifyError({
        summary: "Referenzmessgeräte",
        detail: `Das Referenzmessgerät konnte nicht gespeichert werden. Bitte Ihre Eingaben überprüfen.`,
      });
    }
    console.log("Saving Model:", model);
    const { history } = this.props;
    const onSuccess = () => history.replace("/organisation/messgeraete/admin");
    this.props.saveDevice(model, onSuccess);
  };
  renderMenu = () => {
    return (
      <div className="under-wizard-title">
        <div className="margin-big-top nav-menu-vertical">
          <ul className="w3-ul w3-border w3-round- w3-large">
            <li>
              <NavLink
                to={`/daten`}
                className="disabled-link"
                activeClassName="active"
              >
                {"Allgemeine Daten"}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  render() {
    const { className, device } = this.props;
    console.log("DeviceWizard.render()", device);
    const Menu = this.renderMenu();
    if (!device) return null;
    return (
      <div className={`modem-order-wizard clearfix ${className}`}>
        <WizardTitle
          type="device"
          model={device}
          menu={
            <FormMenu
              className="pad-big-top"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveDeviceBtn2"
              cancelBtnName="cancelDeviceBtn2"
            />
          }
        />
        <div className="container">
          <Dashboard menu={Menu} menuSize={3}>
            <PageHeight30
              id="/daten"
              className="w3-border margin-big-top pad-big"
              isUnterWizardTitle
            >
              {/* <h3 className="no-margin-top">Allgemeine Daten</h3> */}
              <DeviceForm
                key={Math.random()}
                ref={(ref) => (this.form = ref)}
                model={device}
              />
            </PageHeight30>
            <FormMenu
              className="w3-border margin-big-top pad-big w3-light-grey"
              onSave={this.onSave}
              onCancel={this.onCancel}
              saveBtnName="saveDeviceBtn"
              cancelBtnName="cancelDeviceBtn"
            />
          </Dashboard>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Wizard)
);
