import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  PageHeight40,
  PageTitle,
  createSortByAttr,
  scrollToTop,
  TableEmptyMessage,
  toUIDate,
} from "../../../../components/Utils";
import { FormMenu } from "../../../../components/Buttons";
import ItemListManager from "../../../../components/ItemListManager";
import DelphiConnectionTaskForm from "../../components/itConnections/DelphiConnectionTaskForm";
import DelphiConnectionTaskMenu from "../../components/itConnections/DelphiConnectionTaskMenu";
import { filterTasksByTaskType, isDeletionTask } from "../../components/Utils";

import { connect } from "react-redux";
import {
  setSelectedDelphiConnections,
  exportDelphiConnections,
  filterDelphiConnections,
  closeConnectionTask,
  deleteConnectionTask,
} from "../../actions";
import SalesTablePageReport from "../../../Reporting/components/SalesTablePageReport";

const CONNECTION_TYPE = "Delphi";

const mapStateToProps = (state) => {
  return {
    isLoading: state.task.loading,
    delphiConnections: state.task.delphiConnections,
    selection: state.task.selectedDelphiConnections,
    connectionTaskType: state.task.currentDelphiConnectionType,
  };
};
const mapDispatchToProps = (dispatch) => ({
  setSelection: (...args) => dispatch(setSelectedDelphiConnections(...args)),
  exportDelphiConnections: (...args) =>
    dispatch(exportDelphiConnections(...args)),
  filterConnections: (...args) => dispatch(filterDelphiConnections(...args)),
  closeTask: (...args) => dispatch(closeConnectionTask(...args)),
  deleteTask: (...args) => dispatch(deleteConnectionTask(...args)),
});

class DelphiConnectionTaskList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    delphiConnections: PropTypes.array,
    selection: PropTypes.array,
    connectionTaskType: PropTypes.string,
    exportDelphiConnections: PropTypes.func.isRequired,
    filterConnections: PropTypes.func.isRequired,
    rows: PropTypes.number,
  };
  static defaultProps = {
    className: "",
    rows: 10,
  };
  constructor(props) {
    super(props);
    this.state = {
      first: null,
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  onSave = (onHide) => {
    const task = this.form.getModel();
    if (!task) return;
    console.log(`>> saving ${CONNECTION_TYPE} connection task...`, task);
    this.props.closeTask({ connectionType: CONNECTION_TYPE, task }, onHide);
  };
  onDelete = (task) => {
    console.log(`${CONNECTION_TYPE}ConnectionTaskList.onDelete()`, task);
    this.props.deleteTask({ connectionType: CONNECTION_TYPE, task });
  };
  render() {
    // console.log('DelphiConnectionTaskList.render()', this.props);
    const {
      className,
      isLoading,
      rows,
      delphiConnections,
      selection,
      setSelection,
      exportDelphiConnections,
      filterConnections,
      connectionTaskType,
    } = this.props;
    const columnStyle = {
      verticalAlign: "top",
      paddingTop: "16px",
      paddingBottom: "16px",
    };
    const isDeleting = isDeletionTask(connectionTaskType);
    const renderItemTable = (items, actionTemplate) => {
      const taskItems = items
        .map((item) => ({
          ...item,
          user: item.user.split(",").join(" "),
          requester: `${item.requester || ""}`.split(",").join(" "),
          requestDateUI: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ"),
          requestDate: toUIDate(item.request_date, "YYYY-MM-DDTHH:mm:ss:SSSZ", "YYYY.MM.DD"),
          userInCharge: `${item.userInCharge || ""}`.split(",").join(" "),
        }))
        .sort(createSortByAttr("user"));
      return (
        <DataTable
          value={taskItems}
          paginator={true}
          rows={rows}
          first={this.state.first}
          onPage={(event) => {
            this.setState({ first: event.first });
            scrollToTop();
          }}
          sortable
          filter
          filterMatchMode="contains"
          selection={selection}
          onSelectionChange={(event) => setSelection(event.value)}
          paginatorLeft={
            <SalesTablePageReport
              className="pad-lft"
              totalRecords={taskItems.length}
              filteredRecords={taskItems.length}
              first={this.state.first}
              rows={rows}
              emptyText="Keine Delphi-Zugänge"
              itemName="Delphi-Zugang"
              itemNamePlural="Delphi-Zugänge"
            />
          }
          removableSort
          sortMode="multiple"
          emptyMessage={
            isLoading ? null : (
              <TableEmptyMessage itemNamePlural="Delphi-Zugänge" />
            )
          }
        >
          <Column selectionMode="multiple" style={{ ...columnStyle,width: "3em" }} />
          <Column
            field="parentCompanyName"
            header="Vertragspartner"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{...columnStyle}}
          />
          <Column
            field="user"
            header="Mitarbeiter"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{ ...columnStyle,width: "13em" }}
            body={(item) => (
              <a className="text-link" href={`/wizard/organisation/mitarbeiter/${item.user_id}`} target="_blank">
                {item.user}
              </a>
            )}
          />
          {/* <Column
            field="username"
            header="Kennung"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
          /> */}
          <Column
            field="requester"
            header="Beantrager"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{ ...columnStyle,width: "12em" }}
          />
          <Column
            field="requestDate"
            header="Erstelldatum"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{ ...columnStyle,width: "10em", textAlign: "center" }}
            body={item => item.requestDateUI}
          />
          {/* <Column
            field="userInCharge"
            header="Bearbeiter"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{width: '14em'}}
          /> */}
          <Column
            field="status"
            header="Status"
            sortable={taskItems.length > 1}
            filter={taskItems.length > rows}
            filterMatchMode="contains"
            style={{ ...columnStyle,textAlign: "center-", width: "9em" }}
          />
          <Column
            body={actionTemplate}
            style={{ verticalAlign: "top",textAlign: "center", width: "7.4em" }}
          />
        </DataTable>
      );
    };
    const renderItemForm = (item, onHide) => {
      const connection = item;
      return (
        <div>
          <DelphiConnectionTaskForm
            ref={(ref) => (this.form = ref)}
            model={connection}
            isDeleting={isDeleting}
          />
          <FormMenu
            className="margin-big-top w3-border-top pad-big"
            style={{
              marginLeft: "-1em",
              marginRight: "-1em",
              marginBottom: "-1em",
            }}
            onSave={() => this.onSave(onHide)}
            onCancel={onHide}
            saveBtnName="saveDelphiConnectionBtn"
            saveBtnLabel={isDeleting ? "Bestätigen" : undefined}
          />
        </div>
      );
    };
    const getItemFormTitle = (/* connection */) => {
      let title = "Delphi-Zugang bearbeiten";
      if (isDeleting) {
        title = "Delphi-Zugangslöschung bestätigen"
      }
      return <h3 className="no-margin">{title}</h3>;
    };
    const title = (
      <PageTitle>
        <DelphiConnectionTaskMenu
          className="w3-right"
          selectedDelphiConnections={selection}
          exportDelphiConnections={exportDelphiConnections}
          filterConnections={filterConnections}
          connectionTaskType={connectionTaskType}
          items={delphiConnections}
        />
        <h3 className="no-margin pad-btm">{"Delphi-Zugänge"}</h3>
      </PageTitle>
    );
    return (
      <PageHeight40 className={className}>
        <ItemListManager
          key={Math.random()}
          title={title}
          addBtnHidden
          items={delphiConnections.filter((item) =>
            filterTasksByTaskType(item, connectionTaskType)
          )}
          getItemFormTitle={getItemFormTitle}
          renderItemTable={renderItemTable}
          renderItemForm={renderItemForm}
          namePrefix={`${CONNECTION_TYPE}Connection`}
          itemDeleteable
          deleteTitle={`${CONNECTION_TYPE}-Zugangsaufgabe löschen`}
          getDeleteText={(item) =>
            `Wollen Sie diese Aufgabe unwiderruflich löschen?`
          }
          onDelete={this.onDelete}
        />
      </PageHeight40>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DelphiConnectionTaskList);
